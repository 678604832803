


import { mapGetters } from 'vuex';
import Component from 'vue-class-component';
import { Vue, Watch } from 'vue-property-decorator';
import { TContact } from '@/_types/contact.type';
import IconSquareDelete from '@/_modules/icons/components/icon-square-delete.vue';
import IconSearch from '@/_modules/icons/components/icon-search.vue';
import IconSadSmiley from '@/_modules/icons/components/icon-sad-smiley.vue';
import IconCloseQrPopup from '@/_modules/icons/components/qrpopup/icon-close-qr-popup.vue';
import FileHelper from '@/_helpers/file.helper';
import { TApiListResponse } from '@/_types/api/api-list-response.type';
import { TContactTag } from '@/_types/contact-tag.type';
import { TUserShort } from '@/_types/user-short.type';
import { TFile } from '@/_types/file.type';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import eventDiscoveryService, { TEventDiscoveryServiceConfig } from '@/_services/event-discovery.service';
import { TranslateResult } from 'vue-i18n';
import SimplePopup from '@/_modules/controls/components/simple-popup/simple-popup.vue';
import CabinetCreateUser from '@/_modules/promo-cabinet/components/cabinet-create-user/cabinet-create-user.vue';

type TContactRow = {
  id: number;
  event_id?: number;
  name?: string;
  surname?: string;
  email?: string;
  photo_url?: string;
  country?: string;
  city?: string;
  company_name?: string;
  company_website?: string;
  company_position?: string;
  company_description?: string;
  promopage_external_id?: string;
  program_ids?: number[];
  user?: TUserShort;
  is_mine?: boolean;
  is_favorite?: boolean;
  unread_messages?: number;
  files?: TFile[];
  tags?: TContactTag[];
  fullName?: string;
  fullCompany?: string;
  isOnline: boolean;
}

type TContactTagTemporary = {
  name: string;
}

const CONTACTS_PER_PAGE = 40;
const CONTACT_TAGS_QUANTITY_LIMIT = 1000000; // AW-2467. Instead of removing the limit, just made it very big.

@Component({
  components: {
    IconSquareDelete,
    IconSearch,
    IconSadSmiley,
    IconCloseQrPopup,
    SimplePopup,
    CabinetCreateUser,
  },
  computed: {
    ...mapGetters({
      myself: 'promoPageStore/contact',
      contactListLoading: 'contactsStore/isManageContactsPageLoading',
      manageContactsPage: 'contactsStore/manageContactsPage',
      onlineContactIds: 'contactsStore/onlineContactIds',
      event: '_eventStore/event',
      eventContactTags: '_eventStore/eventContactTags',
      isEventContactTagsLoading: '_eventStore/isEventContactTagsLoading',
    }),
  }
})
export default class CabinetContacts extends Vue {

  // DATA
  public currentPageNumber: number = 0;
  public isContactsBeingRequested: boolean = false;
  public searchString: string = '';
  public contactIdToDelete: number = -1;
  public isConfirmDeletePopupVisible: boolean = false;
  public isConfirmDeletePopupBusy: boolean = false;
  public isDeletionErrorVisible: boolean = false;
  public isContactTagsPopupVisible: boolean = false;
  public newTagName: string = '';
  public eventContactTags: TContactTag[];
  public readonly isEventContactTagsLoading: boolean;
  public eventContactTagsTemporary: TContactTagTemporary[] = [];
  public isServiceConnected: boolean = false;
  public isAddUserPopupVisible: boolean = false;

  private destroyed$: Subject<void> = new Subject<void>();

  // MAPPED
  public readonly myself: TContact;
  public readonly contactListLoading: boolean;
  public readonly manageContactsPage: TApiListResponse<TContact>;
  public readonly onlineContactIds: number[];

  // COMPUTED

  public get eventId(): number {
    return (this.$route.params.eventId && parseInt(this.$route.params.eventId, 10)) || null;
  }

  public get currentPageToDisplay(): number {
    return this.currentPageNumber + 1;
  }

  public get totalPages(): number {
    const contactsTotal: number = this.manageContactsPage ? this.manageContactsPage.Total : 0;
    return Math.ceil(contactsTotal / CONTACTS_PER_PAGE);
  }

  public get isEmptySearchResults(): boolean {
    return this.searchString !== '' && this.totalPages === 0;
  }

  public get isPagerNeeded(): boolean {
    return this.totalPages > 0;
  }

  public get skeletonContacts(): TContactRow[] {
    const result: TContactRow[] = [];
    const dummyContact: TContactRow = {
      id: -1,
      email: '-',
      company_name: '-',
      company_position: '-',
      fullName: '-',
      isOnline: false
    };
    for (let i = 0; i < CONTACTS_PER_PAGE; i++) {
      result.push(dummyContact);
    }
    return result;
  }

  public get contactsSinglePage(): TContactRow[] {
    return this.manageContactsPage ? this.contactsToContactRows(this.manageContactsPage.List) : [];
  }

  public get totalOnlineContacts(): number {
    return this.onlineContactIds ? this.onlineContactIds.length : 0;
  }

  public get isContactTagLimitReached(): boolean {
    return CONTACT_TAGS_QUANTITY_LIMIT <= this.eventContactTags.length + this.eventContactTagsTemporary.length;
  }

  public get newTagFormHasErrors(): boolean {
    return this.isContactTagLimitReached || this.isNewTagADuplicate;
  }

  public get isNewTagADuplicate(): boolean {
    if (this.newTagName.trim() === '') {
      return false;
    }
    const newTagName: string = this.newTagName.trim().toLowerCase();
    const existsInTemporaryTags: boolean = this.eventContactTagsTemporary.filter((item: TContactTagTemporary) => item.name.toLowerCase() === newTagName).length > 0;
    const existsInTags: boolean = this.eventContactTags.filter((item: TContactTagTemporary) => item.name.toLowerCase() === newTagName).length > 0;
    return existsInTags || existsInTemporaryTags;
  }

  public get newTagFormErrorText(): string {
    const errors: TranslateResult[] = [];
    if (this.isContactTagLimitReached) {
      errors.push(this.$t('eventPage.edit.tagManagement.tagsAmountLimitReached'));
    }
    if (this.isNewTagADuplicate) {
      errors.push(this.$t('eventPage.edit.tagManagement.tagAlreadyExists'));
    }
    return errors.length ? errors.join(', ') : '';
  }

  // WATCH

  @Watch('myself', {immediate: true})
  private onMyselfChange(): void {
    if (this.myself) {
      this.subscribeToContactEvents();
    }
  }

  @Watch('isServiceConnected', { immediate: true })
  private onIsServiceConnectedChange(): void {
    if (this.isServiceConnected) {
      eventDiscoveryService.subscribe('');
      eventDiscoveryService.requestOnlineContacts();
    }
  }

  // HOOKS

  public mounted(): void {
    this.requestContactsPage();
  }

  public beforeDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  // METHODS

  private subscribeToContactEvents(): void {
    eventDiscoveryService.connected$.pipe(
      takeUntil(this.destroyed$),
    ).subscribe(this.onServiceConnectChange.bind(this));
  }

  private onServiceConnectChange(config: TEventDiscoveryServiceConfig): void {
    this.isServiceConnected = !!config;
  }

  private async requestContactsPage(): Promise<void> {
    this.isContactsBeingRequested = true;
    await this.$store.dispatch('contactsStore/requestManageContactsPage', {
      eventId: this.eventId,
      limit: CONTACTS_PER_PAGE,
      offset: this.currentPageNumber * CONTACTS_PER_PAGE,
      search: this.searchString
    });
    this.isContactsBeingRequested = false;
  }

  private confirmDeleteContact(contactId: number): void {
    if (this.isContactOfCurrentUser(contactId)) {
      this.contactIdToDelete = -1;
      return;
    }
    this.isConfirmDeletePopupBusy = false;
    this.isConfirmDeletePopupVisible = true;
    this.contactIdToDelete = contactId;
  }

  private isContactOfCurrentUser(contactId: number): boolean {
    return this.myself && (this.myself.id === contactId);
  }

  private async deleteContact(): Promise<void> {
    if (this.isContactOfCurrentUser(this.contactIdToDelete)) {
      this.contactIdToDelete = -1;
      return;
    }
    if (this.isConfirmDeletePopupBusy) {
      return;
    }
    this.isConfirmDeletePopupBusy = true;
    await this.$store.dispatch('contactsStore/deleteContact', { eventId: this.eventId, contactId: this.contactIdToDelete});
    this.contactIdToDelete = -1;
    await this.requestContactsPage();
    this.isConfirmDeletePopupVisible = false;
    this.isConfirmDeletePopupBusy = false;
  }

  private cancelContactDeletion(): void {
    if (this.isConfirmDeletePopupBusy) {
      return;
    }
    this.isDeletionErrorVisible = false;
    this.isConfirmDeletePopupVisible = false;
    this.contactIdToDelete = -1;
  }

  public onPreviousPage(): void {
    this.currentPageNumber--;
    this.requestContactsPage();
  }

  public onNextPage(): void {
    this.currentPageNumber++;
    this.requestContactsPage();
  }

  private resetSearch(): void {
    this.searchString = '';
    this.requestContactsPage();
  }

  private async exportEventContacts(exportFileFormat: string): Promise<void> {
    const file = await this.$store.dispatch('statisticStore/exportEventContacts', {
      eventId: this.eventId,
      format: exportFileFormat
    });
    FileHelper.downloadFile(file, 'EventsWallet_event_' + this.eventId + '_contacts.' + exportFileFormat);
  }

  private async getContactTags(): Promise<void> {
    await this.$store.dispatch('_eventStore/getEventContactTags');
  }

  private async saveTemporaryTags(): Promise<void> {
    if (this.eventContactTagsTemporary.length < 1) {
      this.closeContactTagsPopup();
      return;
    }
    const resultingPromises: Promise<void>[] = [];
    this.eventContactTagsTemporary.forEach((item: TContactTagTemporary) => {
      resultingPromises.push(this.$store.dispatch('_eventStore/createContactTag', {
        eventId: this.eventId,
        name: item.name
      }));
    });
    this.eventContactTagsTemporary = [];
    await Promise.all(resultingPromises);
    await this.getContactTags();
    this.closeContactTagsPopup();
  }

  private async removeContactTag(contactTag: TContactTag): Promise<void> {
    await this.$store.dispatch('_eventStore/removeContactTag', {
      eventId: this.eventId,
      tagId: contactTag.id
    });
    await this.getContactTags();
  }

  private removeTemporaryContactTag(index: number): void {
    this.eventContactTagsTemporary.splice(index, 1);
  }

  private addTemporaryNewTag(): void {
    if (this.newTagName.trim() === '' || this.isContactTagLimitReached || this.isNewTagADuplicate ) {
      return;
    }
    const tag: TContactTagTemporary = {
      name: this.newTagName.substring(0, 255),
    };
    this.eventContactTagsTemporary.unshift(tag);
    this.newTagName = '';
  }

  private openContactTagsPopup(): void {
    this.isContactTagsPopupVisible = true;
  }

  private closeContactTagsPopup(): void {
    this.isContactTagsPopupVisible = false;
  }

  private contactsToContactRows(contacts: TContact[]): TContactRow[] {
    return contacts.map(contact => {
      return {
        ...contact,
        isOnline: this.onlineContactIds && (typeof (this.onlineContactIds.find(element => element === contact.id)) !== 'undefined')
      };
    });
  }

  private openAddUserPopup(): void {
    this.isAddUserPopupVisible = true;
  }

  private closeAddUserPopup(): void {
    this.isAddUserPopupVisible = false;
  }

}
